import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header'
import MainWrapper from '../containers/MainWrapper'
import Post from '../components/Post'
import SEO from '../components/SEO'

class Actualites extends React.Component {

  render() {
    const { posts } = this.props.data
    return (
      <React.Fragment>
        <SEO 
          title={`Actualités`} 
          desc={`Actualités du réalisateur Rémy Disch`}
          pathname={this.props.path} 
        />
        <Header {...this.props} />
        <MainWrapper>
          {posts.edges.map((item, index) => {
            return <Post {...item} key={`post-${index}`} />
          })}
        </MainWrapper>
      </React.Fragment>
    )
  }
}

export default Actualites;
export const pageQuery = graphql`
  query Posts {
    posts: allPrismicPost(filter: {data: {is_news_post: {eq: true}}}, sort: {fields: data___date, order: DESC}) {
      edges {
        node {
          data {
            credits {
              html
            }
            contenu {
              html
            }
            title {
              html
            }
            page_link {
              document {
                ... on PrismicPage {
                  uid
                }
              }
            }
            realisation_link {
              document {
                ... on PrismicRealisation {
                  id
                  data {
                    contenu {
                      html
                    }
                    credits {
                      html
                    }
                    titre {
                      html
                    }
                    type
                    video {
                      html
                      embed_url
                      provider_url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`