import React from 'react'
import { Link } from 'gatsby'
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import { PostContainer } from '../containers/Post'
import { VideoContainer } from '../containers/Media'
import { getVideoId, getVideoProvider } from '../utils/mediaHelpers'

const videoOpts = {
  width: '100%',
  playerVars: {
    autoplay: 0,
  },
};

const Post = ({ node: { data } }) => {
  const {
    title,
    contenu,
    credits,
    realisation_link: {
      document: realisation
    }
  } = data;
  
  let video = {};

  if (realisation) {
    const provider_url = realisation.data.video.provider_url;
    const embed_url = realisation.data.video.embed_url;
    
    video.type = getVideoProvider(provider_url);
    video.id = getVideoId(video.type, embed_url);
  }
  
  return (
    <React.Fragment>
      <PostContainer>
        <h2 className="title" dangerouslySetInnerHTML={{ __html: title.html }}></h2>
        {
          credits && (
            <div className="credits" dangerouslySetInnerHTML={{ __html: credits.html }}></div>
          )
        }
        <div className="content" dangerouslySetInnerHTML={{ __html: contenu.html }}></div>
        {
          data.page_link.document && (
            <div className="content">
              <Link to={`/${data.page_link.document.uid}/`}>Plus d'infos</Link>
            </div>
          )
        }
        {
          realisation && (
            <React.Fragment>
              {
                video.type === 'youtube' ? (
                  <VideoContainer>
                    <YouTube 
                      videoId={video.id} 
                      opts={videoOpts} 
                    />
                  </VideoContainer>
                ) : (
                  <VideoContainer>
                    <Vimeo video={video.id} />
                  </VideoContainer>
                )
              }
            </React.Fragment>
          )
        }
      </PostContainer>
    </React.Fragment>
  )
}

export default Post;